import { parseReleaseItems } from '../parseReleaseItems';
import BASE_ITEMS from './v2024_base';
import CLOUDQI_ITEMS from './v2024_cloudQi';
import PQE_ITEMS from './v2024_pqe';
import IQI_ITEMS from './v2024_iqi';
import PDI_ITEMS from './v2024_pdi';
import PQI_ITEMS from './v2024_pqi';
import PSI_ITEMS from './v2024_psi';
import MHI_ITEMS from './v2024_mhi';
import SAS_QI_ITEMS from './v2024_sasQi';
import WINQI_ITEMS from './v2024_winQi';

export const VERSION = 'v2024';
export const REVISION = 'September 2024';
export const ITEMS = [
  ...parseReleaseItems(VERSION, REVISION, undefined, BASE_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'CLOUDQI', CLOUDQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'SASQI', SAS_QI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'WINQI', WINQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PQE', PQE_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'IQI', IQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PDI', PDI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PQI', PQI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'PSI', PSI_ITEMS),
  ...parseReleaseItems(VERSION, REVISION, 'MHI', MHI_ITEMS),
];

export default ITEMS;
