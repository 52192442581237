import { getReleaseItem } from '../releases';

export const mhiMeasuresData = {
  technical: [
    {
      ...getReleaseItem('IndividualMeasureTechnicalSpecifications', 'MHI'),
      status: 'new',
    },

    {
      ...getReleaseItem('BenchmarkDataTables', 'MHI'),
      status: 'new',
    },
  ],
  additional: [
    {
      title: 'AHRQ Announces the Maternal Health Indicators',
      desc: "<p>Read AHRQ's announcement of the Maternal Health Indicators (MHI) module and the release of MHI Beta Software v2024.</p>",
      url: '/Downloads/Resources/v2024_MHI_Beta_Software_Announcement.pdf',
      aria: 'AHRQ Announces the Maternal Health Indicators',
      cta: 'Download (PDF File, 228 KB)',
    },
    {
      title: 'Refining the Severe Maternal Morbidity Measure',
      desc: "<p>Learn about AHRQ's scientific rationale and empirical testing for refining the Severe Maternal Morbidity measure.</p>",
      url: '/Downloads/Resources/v2024_MHI_Scientific_Rationale_and_Empirical_Testing.pdf',
      aria: 'Refining the Severe Maternal Morbidity Measure',
      cta: 'Download (PDF File, 311 KB)',
    },
  ],
};
