import React, { useRef } from 'react';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import { Box } from '@mui/material';
import QICard from '../../Common/QICard/QICard';
import { mhiMeasuresData } from '../../../data/measures/mhi';
import { Button, ImagePageRow, PageHeader, PageRow, Tile, TileGroup, Flex, LinkItem } from '../../../ui';

const MHIResources = () => {
  const scrollToResourcesRef = useRef(null);
  const scrollToSoftwareRef = useRef(null);

  return (
    <>
      <PageHeader.Row bgSrc='/images/measures-hero-bg.jpg' split sx={{ paddingBottom: '6rem' }}>
        <PageHeader.Title>
          <sup className='highlightCaps'>Beta</sup>
          Maternal Health Indicators
        </PageHeader.Title>
        <PageHeader.Content>
          <div>
            The Maternal Health Indicators (<strong>MHI</strong>s) aim to broadly address healthcare
            quality in the domain of maternal health and identify opportunities to reduce
            complications during the peripartum period.
          </div>
          <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={2}>
            <Button
              label='Go to the MHI Resources'
              onClick={() => scrollToResourcesRef.current.scrollIntoView()}
              arrowDown
            />
            <Button
              label={`MHI Beta Software`}
              onClick={() => scrollToSoftwareRef.current.scrollIntoView()}
              arrowDown
            />
          </Box>
        </PageHeader.Content>
      </PageHeader.Row>

      <ImagePageRow
        bgSrc='/images/hex-purple.png'
        imageSrc='/images/bg-mhi-indicators-uses.jpg'
        purple
      >
        <h2>How are Maternal Health Indicators used?</h2>
        <p>
          The Maternal Health Indicators (MHIs) are geographic area level rates of severe maternal
          morbidity (SMM) and mortality that could potentially be prevented by high quality health
          care. The measures are identified via delivery discharge claims data and can be used for
          population health analysis, surveillance, quality assurance, and research purposes.
        </p>
        <Box display='flex' flexDirection={{ xs: 'column', lg: 'column' }}>
          <LinkItem href='/Downloads/Resources/v2024_MHI_Beta_Software_Announcement.pdf' meta='PDF File, 228 KB' mb noborder>
            View Announcement
          </LinkItem>
          <LinkItem href='/Downloads/Resources/v2024_MHI_Scientific_Rationale_and_Empirical_Testing.pdf' meta='PDF File, 311 KB' light mb noborder>
            View Scientific Rationale
          </LinkItem>
        </Box>
      </ImagePageRow>

      <PageRow
        sx={{
          paddingTop: '7rem',
          borderBottom: '1px solid',
          borderColor: (t) => t.palette.divider,
        }}
      >
        <Flex margin='0 auto' maxWidth={800} textAlign='center' gap={2} mb={2}>
          <p>
            The MHIs are released as a beta module and AHRQ invites feedback on their use and
            validation by researchers and other users. AHRQ is currently developing additional
            measures to potentially address healthcare quality during the peripartum periods. The
            MHI measures are meant to be used at the area level and are not intended as
            accountability measures.
          </p>

          <h2 ref={scrollToSoftwareRef} className='flex'>
            <div className='flex flex-row mx-auto'>
              <div className='flex flex-column'>
                <span style={{ 'align-self': 'flex-start' }}>
                  <sub className='highlightCaps'>BETA</sub>
                </span>
                <span>MHI</span>
              </div>
              <div>&nbsp;</div>
              <div style={{ 'align-self': 'flex-end' }}>Software Available</div>
            </div>
          </h2>
          <p>
            AHRQ offers free software to help users using the AHRQ QIs generate results that are
            both accurate and actionable. Use of this free software ensures a standard, trusted
            approach to quality measurement and means more resources are available for supporting
            improvements to patient care.
          </p>
        </Flex>
        <TileGroup type='measures' columns={{ xs: 2, md: 4, lg: 4, xl: 4 }} gap='sm' py={3}>
          <Tile
            title='SAS QI v2024'
            description='Ideal for researchers and advanced users to run in a SAS/STAT software package.'
            to='/software/sas_qi'
            toLabel='Now available, MHI v2024.0.1'
          />
          <Tile
            title='CloudQI v2024'
            description={`CloudQI's robust user interface is ideal for non-programmers and health care professionals.`}
            to='/software/cloudqi'
            toLabel='Now available, MHI v2024.0.1'
          />
          <Tile
            title='Frequently Asked Questions'
            description={`Read the latest software release FAQ (PDF File, 422 KB)`}
            buttonTo='/News/ICD10_v2024_FAQ_MHI.pdf'
            buttonLabel='Learn More (PDF file, 422 KB)'
          />
        </TileGroup>
      </PageRow>

      <PageRow bgSrc='/images/bg-tech-specs-list.jpg' bgSrcNone='sm' sx={{ paddingTop: '7rem' }}>
        <Box
          pb={6}
          textAlign={{ xs: 'center', sm: 'left' }}
          width={{ xs: '100%', sm: '60%', lg: '50%' }}
        >
          <h2>{mhiMeasuresData.technical[0].title}</h2>
          <p>{mhiMeasuresData.technical[0].desc}</p>
          <Button
            label={mhiMeasuresData.technical[0].cta}
            aria-label={mhiMeasuresData.technical[0].aria}
            href={mhiMeasuresData.technical[0].url}
            arrow
          />
        </Box>
      </PageRow>

      <PageRow lightBlue>
        <h2 ref={scrollToResourcesRef} className='align-center'>
          MHI Resources
        </h2>
        <Tabs defaultActiveKey='technical' className='qiTabs'>
          <Tab id='techspecs' eventKey='technical' title='Technical Specifications'>
            <Container>
              <Row>
                {mhiMeasuresData.technical.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey='additional' title='Additional MHI Resources'>
            <Container>
              <Row>
                {mhiMeasuresData.additional.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </PageRow>
    </>
  );
};

export default MHIResources;
