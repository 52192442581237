import React, { useRef } from 'react';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import { Box } from '@mui/material';
import QICard from '../../Common/QICard/QICard';
import { iqiMeasuresData } from '../../../data/measures/iqi';
import {
  Button,
  ImagePageRow,
  LinkItem,
  PageDisclaimer,
  PageHeader,
  PageRow,
  Tile,
  TileGroup,
} from '../../../ui';
import { releaseVersion } from '../../../data/releases';

const IQIResources = () => {
  const scrollToResourcesRef = useRef(null);
  const scrollToSoftwareRef = useRef(null);

  return (
    <>
      <PageHeader.Row bgSrc='/images/measures-hero-bg.jpg' split sx={{ paddingBottom: '6rem' }}>
        <PageHeader.Title>Inpatient Quality Indicator Measures</PageHeader.Title>
        <PageHeader.Content>
          <div>
            The Inpatient Quality Indicators (<strong>IQI</strong>s) provide a perspective on
            quality of care inside hospitals, including: Inpatient mortality for surgical procedures
            and medical conditions; Utilization of procedures for which there are questions of
            overuse, underuse, and misuse.
          </div>
          <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={2}>
            <Button
              label='Go to the IQI Resources'
              onClick={() => scrollToResourcesRef.current.scrollIntoView()}
              arrowDown
            />
            <Button
              label={`View Software Below`}
              onClick={() => scrollToSoftwareRef.current.scrollIntoView()}
              variant='text'
              sx={{ fontWeight: 400 }}
              arrowDown
            />
          </Box>
        </PageHeader.Content>
      </PageHeader.Row>

      <ImagePageRow
        bgSrc='/images/hex-purple.png'
        imageSrc='/images/bg-iqi-indicators-uses.jpg'
        purple
      >
        <h2>How are Inpatient Quality Indicators used?</h2>
        <p>
          The IQIs can be used to help hospitals assess quality of care inside the hospital and
          identify areas that might need further study.
        </p>
        <p>Download information about AHRQ's Quality Indicators below:</p>
        <div>
          <LinkItem
            href='/Downloads/Modules/V2024/AHRQ_QI_Full_Brochure.pdf'
            meta='PDF File, 2.64 MB'
            light
            mb
            noborder
          >
            AHRQ Quality Indicators Brochure
          </LinkItem>
          <LinkItem
            href='/Downloads/Modules/V2024/AHRQ_QI_Indicators_List.pdf'
            meta='PDF File, 264 KB'
            mb
            noborder
          >
            List of all Indicators
          </LinkItem>
        </div>
      </ImagePageRow>

      <PageRow
        sx={{
          paddingTop: '7rem',
          borderBottom: '1px solid',
          borderColor: (t) => t.palette.divider,
        }}
      >
        <h2 ref={scrollToSoftwareRef} className='align-center'>
          AHRQ QI Software
        </h2>
        <p>
          AHRQ offers free software to help users using the AHRQ QIs generate results that are both
          accurate and actionable. Use of this free software ensures a standard, trusted approach to
          quality measurement and means more resources are available for supporting improvements to
          patient care.
        </p>
        <TileGroup type='measures' columns={{ xs: 2, md: 4, lg: 4, xl: 4 }} gap='sm' py={3}>
          <Tile
            title='SAS QI v2024'
            description='Ideal for advanced users to run in a SAS/STAT software package.'
            to='/software/sas_qi'
            toLabel='Now available, SAS QI v2024'
          />
          <Tile
            title='WinQI v2024'
            description={`WinQI's robust user interface is ideal for non-programmers.`}
            to='/software/win_qi'
            toLabel='Now available, WinQI v2024'
          />
        </TileGroup>
      </PageRow>

      <PageRow bgSrc='/images/bg-tech-specs-list.jpg' bgSrcNone='sm' sx={{ paddingTop: '7rem' }}>
        <Box
          pb={6}
          textAlign={{ xs: 'center', sm: 'left' }}
          width={{ xs: '100%', sm: '60%', lg: '50%' }}
        >
          <h2>{iqiMeasuresData.technical[0].title}</h2>
          <p>{iqiMeasuresData.technical[0].desc}</p>
          <Button
            label={iqiMeasuresData.technical[0].cta}
            aria-label={iqiMeasuresData.technical[0].aria}
            href={iqiMeasuresData.technical[0].url}
            arrow
          />
        </Box>
      </PageRow>

      <PageRow lightBlue>
        <h2 ref={scrollToResourcesRef} className='align-center'>
          IQI Resources
        </h2>
        <Tabs defaultActiveKey='technical' className='qiTabs'>
          <Tab id='techspecs' eventKey='technical' title='Technical Specifications'>
            <Container>
              <Row>
                {iqiMeasuresData.technical.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey='additional' title='Additional IQI Resources'>
            <Container>
              <Row>
                {iqiMeasuresData.additional.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightBlue py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ IQI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </>
  );
};

export default IQIResources;
