import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TileGroup, Tile } from '../../../ui';

export const LatestNews = () => {
  return (
    <Container>
      <Typography variant='h3' align='center' mt={8}>
        Read the Latest News
      </Typography>
      <TileGroup type='news' py={3}>
        <Tile
          title='Release of AHRQ SAS QI, WinQI, and CloudQI v2024.0.1 Software.'
          description='This is a minor release of AHRQ QI Software to introduce the Maternal Health Indicators (MHI) and other updates. Details are in the Software Release Notes for each software product.'
          label='Software'
          date='September 23, 2024'
          to='/news#software-release-2024-09-23'
          promote
        />
        <Tile
          title='Beta release of a new module: Maternal Health Indicators (MHI), 2024.'
          description={`The MHI module aims to broadly address healthcare quality in the domain of maternal health and identify opportunities to reduce complications during the peripartum period. Read AHRQ's announcement to learn more.`}
          label='Software'
          date='September 23, 2024'
          to='/news#mhi-release-2024-09-23'
          promote
        />
        <Tile
          title='Release of AHRQ QI SAS QI, WinQI, and CloudQI v2024 Software.'
          description='The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.'
          label='Software'
          date='July 26, 2024'
          to='/news#software-release-2024-07-26'
        />
      </TileGroup>
      <Box mb={8} textAlign='center'>
        <Typography variant='h5' color='primary'>
          <Link to='/news'>
            See all news <ChevronRightIcon />
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};
