import React from 'react';
import TechSpecTable from '../TechSpecTable';
import {
  Button,
  Rule,
  Flex,
  PageHeader,
  PageRow,
  PageDisclaimer,
} from '../../../../ui';
import { getReleaseItem, releaseVersion } from '../../../../data/releases';

const MHITechnical = () => {
  const techSpecFile = getReleaseItem('TechSpecs', 'MHI');
  const techSpecExcelFile = getReleaseItem('TechSpecsExcel', 'MHI');
  const sasReleaseNotes = getReleaseItem('ReleaseNotes', 'SASQI');
  return (
    <>
      <PageHeader.Row bgSrc='/images/hex-grey.jpg' lightGrey>
        <PageHeader.Title>Technical Specifications</PageHeader.Title>
        <PageHeader.Content>
          <h4>For Maternal Health Indicators</h4>
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow>
        <Flex gap={4}>
          <h4>
            {techSpecFile.title} (PDF Format) - Version {techSpecFile.version},{' '}
            {techSpecFile.revision}
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated {techSpecFile.title} (PDF Format), Version {techSpecFile.version} (
              {techSpecFile.format} File)
            </Flex>
            <Button aria-label={techSpecFile.aria} href={techSpecFile.url}>
              {techSpecFile.cta}
            </Button>
          </Flex>

          <h4>
            {techSpecExcelFile.title} - Version {techSpecExcelFile.version},{' '}
            {techSpecExcelFile.revision}
          </h4>
          <Rule spacing={0} />
          <Flex flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center' gap={2}>
            <Flex>
              Updated {techSpecExcelFile.title}, Version {techSpecExcelFile.version} (
              {techSpecExcelFile.format} File)
            </Flex>
            <Button aria-label={techSpecExcelFile.aria} href={techSpecExcelFile.url}>
              {techSpecExcelFile.cta}
            </Button>
          </Flex>

          <TechSpecTable module={'mhi'} version={'icd10_v2024'} />
          <div>
            ** To learn about the treatment of COVID-19 codes in the AHRQ Quality Indicators (QIs),
            refer to{' '}
            <a
              href={sasReleaseNotes.url}
              aria-label={sasReleaseNotes.aria}
              rel='noreferrer'
              target='_blank'
            >
              {sasReleaseNotes.title} {sasReleaseNotes.info}
            </a>
          </div>
        </Flex>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightGrey py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ MHI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </>
  );
};

export default MHITechnical;
