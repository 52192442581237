const BASE_ITEMS = [
  // DOWNLOADS/MODULES
  {
    name: 'FiscalYearCodingRevisions',
    title: 'Annual fiscal year ICD-10-CM/PCS coding revisions',
    desc: 'This document contains the annual fiscal year (FY) ICD-10-CM/PCS coding revisions made to a subset of the setnames used to specify the QIs in the AHRQ QI software. The FY coding updates reflect ICD-10-CM/PCS coding changes implemented in the Centers for Medicare and Medicaid Services IPPS Final Rule. Through clinical and coding expert review of the Final Rule, we determined whether the concepts captured in the coding changes were applicable to the setnames used to specify the QIs.',
    filename: 'v2024_FY_Coding_Updates.pdf',
    path: '/Downloads/Modules/V2024/',
    size: '86 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },

  // DOWNLOADS/RESOURCES
  {
    name: 'EmpiricalMethods',
    title: 'AHRQ Quality Indicator Empirical Methods',
    desc: 'Describes the empirical methods used to calculate AHRQ QIs',
    filename: 'Empirical_Methods_2024.pdf',
    path: '/Downloads/Resources/Publications/2024/',
    size: '1.18 MB',
    format: 'PDF',
    revision: 'July 2024',
    dateModified: '2024-07-26',
  },
  // DOWNLOADS/SOFTWARE
  {
    name: 'SASAndWinQIComparisonTesting',
    title: 'SAS v2024 and WinQI v2024 Comparison Testing',
    filename: 'SAS-WindowsSoftware_comparison_ICD-10v2024.pdf',
    path: '/Downloads/Software/V2024/',
    size: '249 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'AHRQQIRateComparison',
    title: 'AHRQ QI Rate Comparison: SAS QI v2023 vs. SAS QI v2024',
    filename: 'AHRQ_QI_Rate_Comparison_v2023_v2024.pdf',
    path: '/Downloads/Software/V2024/',
    size: '252 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },

  // NEWS
  {
    name: 'Icd10Faq',
    title: 'ICD10 v2024 FAQ',
    filename: 'ICD10_v2024_FAQ.pdf',
    path: '/News/',
    size: '319 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'Icd10FaqMinor',
    title: 'ICD10 v2024.0.1 FAQ',
    filename: 'ICD10_v2024_FAQ_MHI.pdf',
    path: '/News/',
    size: '422 KB',
    format: 'PDF',
    dateModified: '2024-09-23',
    minorVersion: '.0.1'
  },
];

export default BASE_ITEMS;
