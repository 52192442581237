const MenuData = [
  {
    title: 'Measures',
    path: '/measures',
    submenu: [
      {
        subtitle: 'Overview',
        path: '/measures/qi_resources',
      },
      {
        subtitle: 'PQI Resources',
        path: '/measures/pqi_resources',
        children: ['/measures/pqi_techspec', '/measures/pqi_log_coding_updates/'],
      },
      {
        subtitle: 'IQI Resources',
        path: '/measures/iqi_resources',
        children: ['/measures/iqi_techspec', '/measures/iqi_log_coding_updates/'],
      },
      {
        subtitle: 'PSI Resources',
        path: '/measures/psi_resources',
        children: ['/measures/psi_techspec', '/measures/psi_log_coding_updates/'],
      },
      {
        subtitle: 'PDI Resources',
        path: '/measures/pdi_resources',
        children: ['/measures/pdi_techspec', '/measures/pdi_log_coding_updates/'],
      },
      {
        subtitle: 'PQE Resources',
        path: '/measures/pqe_resources',
        children: [
          '/measures/pqe_techspec',
          '/measures/pqe_log_coding_updates/',
          '/measures/how_to_use_pqe_resources',
        ],
      },
      {
        subtitle: 'MHI Resources',
        path: '/measures/mhi_resources',
        children: [
          '/measures/mhi_techspec',
          '/measures/mhi_log_coding_updates/',
          '/measures/how_to_use_mhi_resources',
        ],
      },
      {
        subtitle: 'All Measures',
        path: '/measures/all_measures',
      },
    ],
    promote: {
      title: 'New! Cloud QI',
      description:
        'Windows software that includes the PSI, PQE, and MHI modules is available to all AHRQ QI users.',
      uri: '/software/cloudqi',
    },
  },
  {
    title: 'Software',
    path: '/software',
    submenu: [
      {
        subtitle: 'Overview',
        path: '/software/qi',
      },
      {
        subtitle: 'SAS QI',
        path: '/software/sas_qi',
      },
      {
        subtitle: 'WinQI',
        path: '/software/win_qi',
      },
      {
        subtitle: 'CloudQI',
        path: '/software/cloudqi',
      },
    ],
    promote: {
      title: 'New! CloudQI',
      description:
        'Windows software that includes the PSI and PQE modules is available to all AHRQ QI users.',
      uri: '/software/cloudqi',
    },
  },
  {
    title: 'Resources',
    path: '/resources',
    submenu: [
      {
        subtitle: 'Overview',
        path: '/resources/landing',
      },
      {
        subtitle: 'Webinars',
        path: '/resources/webinars',
      },
      {
        subtitle: 'Case Studies',
        path: '/resources/case_studies',
      },
      {
        subtitle: 'Presentations',
        path: '/resources/presentations',
      },
      {
        subtitle: 'Publications',
        path: '/resources/publications',
      },
      {
        subtitle: 'Toolkits',
        path: '/resources/toolkits',
      },
    ],
  },
  {
    title: 'FAQs',
    path: '/faqs',
    submenu: [],
  },
  {
    title: 'Archives',
    path: '/archive',
    submenu: [
      {
        subtitle: 'Overview',
        path: '/archive/landing',
      },
      {
        subtitle: 'QI Modules',
        path: '/archive/qi_modules',
      },
      {
        subtitle: 'News',
        path: '/archive/news',
      },
      {
        subtitle: 'Software',
        path: '/archive/software',
      },
      {
        subtitle: 'Resources',
        path: '/archive/resources',
      },
    ],
  },
];

export default MenuData;
