const PDI_ITEMS = [
  {
    name: 'TechSpecs',
    title: 'Pediatric Quality Indicators Technical Specifications',
    filename: 'PDI_2024_ICD10_techspecs_pdf.zip',
    path: '/Downloads/Modules/PDI/V2024/TechSpecs/',
    size: '4.81 MB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    dateModified: '2024-07-26',
  },
  {
    name: 'TechSpecsExcel',
    title: 'Pediatric Quality Indicators Technical Specifications (Excel Format)',
    filename: 'PDI_2024_ICD10_techspecs_excel.zip',
    path: '/Downloads/Modules/PDI/V2024/TechSpecs/',
    size: '962 KB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    dateModified: '2024-07-26',
  },
  {
    name: 'ParameterEstimates',
    title: 'Parameter Estimates for v2024 ICD-10-CM/PCS',
    desc: 'Tables of PDI covariates and coefficients for risk adjustment logistic regression models.',
    filename: 'Parameter_Estimates_PDI_v2024.pdf',
    path: '/Downloads/Modules/PDI/V2024/',
    size: '1.86 MB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'BenchmarkDataTables',
    title: 'Benchmark Data Tables for v2024 ICD-10-CM/PCS',
    desc: 'Tables of nationwide comparative rates for PDIs including observed rate, numerator, and denominator data for each indicator overall and stratified by sex and age group.',
    filename: 'Version_2024_Benchmark_Tables_PDI.pdf',
    path: '/Downloads/Modules/PDI/V2024/',
    size: '658 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'CompositeMeasures',
    title:
      'Quality Indicator User Guide: Pediatric Quality Indicators (PDI) Composite Measures, July 2024',
    desc: 'Document providing an overview of the composite measures, including how they are created and maintained and other supporting resources.',
    filename: 'PDI_Composite_Measures.pdf',
    path: '/Downloads/Modules/PDI/V2024/',
    size: '242 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'LogOfUpdatesRevisions',
    title: 'Log of Updates and Revisions for v2024',
    desc: 'This document contains tables summarizing revisions made to the PDI software, software documentation and technical specifications since the original release of these documents in November 2001.',
    filename: 'ChangeLog_PDI_v2024.pdf',
    path: '/Downloads/Modules/PDI/V2024/',
    size: '2.56 MB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'CodingRevisionsExcel',
    title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
    desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
    filename: 'AHRQ_PDI_v2024_Code_Set_Changes.xlsx',
    path: '/Downloads/Modules/PDI/V2024/',
    size: '19.1 MB',
    format: 'EXCEL',
    dateModified: '2024-07-26',
  },

  // LINKS
  {
    name: 'IndividualMeasureTechnicalSpecifications',
    title: 'Individual Measure Technical Specifications (v2024 coding)',
    desc: 'Breaks down calculations used to formulate each PDI, including a brief description of the measure, numerator and denominator information, and details on cases that should be excluded from calculations. ',
    url: '/measures/PDI_TechSpec',
    aria: 'View PDI Technical Specifications',
    cta: 'Learn More',
  },
  {
    name: 'LogOfCodingUpdates',
    title: 'Log of Coding Updates and Revisions for v2024',
    desc: 'Documents summarizing revisions made to the PDI software, software documentation and technical specifications since the original release of these documents in November 2001.',
    url: '/measures/pdi_log_coding_updates/v2024',
    aria: 'View Log Coding Updates PDI v2024',
    cta: 'Learn More',
  },
];

export default PDI_ITEMS;
