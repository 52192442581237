const TechSpecComponents = {
  mhi: {
    icd10_v2024: require('./MHI/icd10_v2024').default,
  },
  pqe: {
    icd10_v2024: require('./PQE/icd10_v2024').default,
    icd10_v2023: require('./ED-PQI/icd10_v2023').default,
  },
  pqi: {
    icd10_v2024: require('./PQI/icd10_v2024').default,
    icd10_v2023: require('./PQI/icd10_v2023').default,
    icd10_v2022: require('./PQI/icd10_v2022').default,
    icd10_v2021: require('./PQI/icd10_v2021').default,
    icd10_v2020: require('./PQI/icd10_v2020').default,
    icd10_v2019: require('./PQI/icd10_v2019').default,
    icd10_v2018: require('./PQI/icd10_v2018').default,
    icd10_v70: require('./PQI/icd10_v70').default,
    icd10_v60: require('./PQI/icd10_v60').default,
    icd9_v60: require('./PQI/icd9_v60').default,
    icd10_v50: require('./PQI/icd10_v50').default,
    icd9_v50: require('./PQI/icd9_v50').default,
    v45: require('./PQI/v45').default,
    v44: require('./PQI/v44').default,
    v43: require('./PQI/v43').default,
    v43a: require('./PQI/v43a').default,
    v42: require('./PQI/v42').default,
    v41: require('./PQI/v41').default,
  },
  iqi: {
    icd10_v2024: require('./IQI/icd10_v2024').default,
    icd10_v2023: require('./IQI/icd10_v2023').default,
    icd10_v2022: require('./IQI/icd10_v2022').default,
    icd10_v2021: require('./IQI/icd10_v2021').default,
    icd10_v2020: require('./IQI/icd10_v2020').default,
    icd10_v2019: require('./IQI/icd10_v2019').default,
    icd10_v2018: require('./IQI/icd10_v2018').default,
    icd10_v70: require('./IQI/icd10_v70').default,
    icd10_v60: require('./IQI/icd10_v60').default,
    icd9_v60: require('./IQI/icd9_v60').default,
    icd10_v50: require('./IQI/icd10_v50').default,
    icd9_v50: require('./IQI/icd9_v50').default,
    v45: require('./IQI/v45').default,
    v44: require('./IQI/v44').default,
    v43: require('./IQI/v43').default,
    v43a: require('./IQI/v43a').default,
    v42: require('./IQI/v42').default,
    v41: require('./IQI/v41').default,
  },
  psi: {
    icd10_v2024: require('./PSI/icd10_v2024').default,
    icd10_v2023: require('./PSI/icd10_v2023').default,
    icd10_v2022: require('./PSI/icd10_v2022').default,
    icd10_v2021: require('./PSI/icd10_v2021').default,
    icd10_v2020: require('./PSI/icd10_v2020').default,
    icd10_v2019: require('./PSI/icd10_v2019').default,
    icd10_v2018: require('./PSI/icd10_v2018').default,
    icd10_v70: require('./PSI/icd10_v70').default,
    icd10_v60: require('./PSI/icd10_v60').default,
    icd9_v60: require('./PSI/icd9_v60').default,
    icd10_v50: require('./PSI/icd10_v50').default,
    icd9_v50: require('./PSI/icd9_v50').default,
    v45: require('./PSI/v45').default,
    v45a: require('./PSI/v45a').default,
    v44: require('./PSI/v44').default,
    v43: require('./PSI/v43').default,
    v43a: require('./PSI/v43a').default,
    v42: require('./PSI/v42').default,
    v41: require('./PSI/v41').default,
  },
  pdi: {
    icd10_v2024: require('./PDI/icd10_v2024').default,
    icd10_v2023: require('./PDI/icd10_v2023').default,
    icd10_v2022: require('./PDI/icd10_v2022').default,
    icd10_v2021: require('./PDI/icd10_v2021').default,
    icd10_v2020: require('./PDI/icd10_v2020').default,
    icd10_v2019: require('./PDI/icd10_v2019').default,
    icd10_v2018: require('./PDI/icd10_v2018').default,
    icd10_v70: require('./PDI/icd10_v70').default,
    icd10_v60: require('./PDI/icd10_v60').default,
    icd9_v60: require('./PDI/icd9_v60').default,
    icd10_v50: require('./PDI/icd10_v50').default,
    icd9_v50: require('./PDI/icd9_v50').default,
    v45: require('./PDI/v45').default,
    v44: require('./PDI/v44').default,
    v43: require('./PDI/v43').default,
    v43a: require('./PDI/v43a').default,
    v42: require('./PDI/v42').default,
    v41: require('./PDI/v41').default,
  },
};

export default TechSpecComponents;
