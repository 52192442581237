import React from 'react';
import { Tile, TileGroup } from '../../../ui';

export const QITileCloudQI = ({ isTechSpecs }) => {
  return (
    <TileGroup type='measures' columns={{ xs: 1, md: 4, lg: 6, xl: 6, xxl: 6 }} gap='md' py={3}>
      <Tile
        title='PSI'
        subtitle='Patient Safety Indicators'
        description='The Patient Safety Indicators (PSIs) provide information on potentially avoidable safety events that represent opportunities for improvement in the delivery of care.'
        to={isTechSpecs ? '/measures/PSI_TechSpec' : '/measures/psi_resources'}
        toLabel={isTechSpecs ? 'View PSI Technical Specifications' : 'View PSI Measures'}
      />
      <Tile
          title='PQE'
          subtitle='Prevention Quality Indicators in Emergency Department Settings'
          description='The Prevention Quality Indicators in Emergency Department Settings (PQE) identify issues of access to outpatient care, including appropriate follow-up care after hospital discharge.'
          to={isTechSpecs ? '/measures/pqe_TechSpec' : '/measures/pqe_resources'}
          toLabel={isTechSpecs ? 'View PQE Technical Specifications' : 'View PQE Measures'}
        />
        <Tile
          title='MHI'
          subtitle='Maternal Health Indicators'
          description='The Maternal Health Indicators (MHIs) are geographic area level rates of severe maternal morbidity and mortality that could potentially be prevented by high quality health care.'
          to={isTechSpecs ? '/measures/mhi_TechSpec' : '/measures/mhi_resources'}
          toLabel={isTechSpecs ? 'View MHI Technical Specifications' : 'View MHI Measures'}
          mdOffset={1}
          lgOffset={0}
          promote
        />
    </TileGroup>
  );
};
