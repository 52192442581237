const WINQI_ITEMS = [
  // DOWNLOADS/SOFTWARE/WINQI
  {
    name: 'WinQi',
    title: 'WinQI v2024.0.1',
    filename: 'quality_indicators_2024.0.1_x64_Setup.zip',
    path: '/Downloads/Software/WinQI/V2024/',
    size: '249 MB',
    format: 'ZIP',
    ctaLabel: 'Download WinQI v2024.0.1',
    dateModified: '2024-09-23',
  },
  {
    name: 'ReleaseNotes',
    title: 'v2024 Release Notes',
    filename: 'AHRQ_Windows_v2024.0.1_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/WinQI/V2024/',
    size: '345 KB',
    format: 'PDF',
    dateModified: '2024-09-23',
    minorVersion: '.0.1'
  },
  {
    name: 'WinQiSoftwareInstructions',
    title: 'AHRQ Quality Indicators Software Instructions v2024.0.1',
    filename: 'Software_Inst_WINQI_V2024_July_2024.pdf',
    path: '/Downloads/Software/WinQI/V2024/',
    size: '5.92 MB',
    format: 'PDF',
    dateModified: '2024-09-23',
  },

  // PQE
  {
    name: 'WinQiEdPqi',
    title: 'ED PQI v2023.0.1',
    filename: 'edpqi_1.0.1_x64_Setup.zip',
    path: '/Downloads/Software/WinQI/V2023/',
    size: '100 MB',
    format: 'ZIP',
    ctaLabel: 'Download ED PQI v2023.0.1',
    revision: 'December 2023',
    dateModified: '2023-12-21',
  }
];

export default WINQI_ITEMS;
