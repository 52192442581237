const CLOUDQI_ITEMS = [
  {
    name: 'CloudQi',
    title: 'CloudQI v2024.0.1',
    filename: 'cloudqi_2.0.1_x64_Setup.zip',
    path: '/Downloads/Software/CloudQI/V2024/',
    size: '135.0 MB',
    format: 'ZIP',
    ctaLabel: 'Download CloudQI v2024.0.1',
    dateModified: '2024-09-23',
  },
  {
    name: 'PsiInfoSheet',
    title: 'CloudQI PSI Software Information Sheet',
    desc: 'A software for the PSI module that supports multiple versions. This software can be easily installed on desktops or local cloud environments, allowing seamless remote access via any web browser. It is the perfect solution for PSI users seeking convenient remote accessibility.',
    filename: 'CloudQI_Software_Information_Sheet_v2024.0.1.pdf',
    path: '/Downloads/Software/CloudQI/V2024/',
    size: '230 KB',
    format: 'PDF',
    dateModified: '2024-09-23',
    minorVersion: '.0.1'
  },
  {
    name: 'CloudQIReleaseNotes',
    title: 'CloudQI v2024 Release Notes',
    filename: 'AHRQ_Windows_v2024.0.1_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/WinQI/V2024/',
    size: '345 KB',
    format: 'PDF',
    ctaLabel: 'Download v2024 release notes',
    revision: 'July 2024',
    dateModified: '2024-09-23',
    minorVersion: '.0.1',
  },
  {
    name: 'CloudQiSoftwareInstructions',
    title: 'AHRQ Quality Indicators Software Instructions (CloudQI) v2024.0.1',
    filename: 'Software_Inst_CloudQI_V2024.0.1_September_2024.pdf',
    path: '/Downloads/Software/CloudQI/V2024/',
    size: '7.30 MB',
    format: 'PDF',
    dateModified: '2024-09-23',
  },
];

export default CLOUDQI_ITEMS;
