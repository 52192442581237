import React from 'react';
import { Container } from 'react-bootstrap';
import { TileGroup, Tile } from '../../../ui';
import styles from '../home.module.scss';

export const GetToKnowAHRQ = () => {
  return (
    <div className={styles.GetToKnowAHRQ}>
      <Container className={styles.GetToKnowAHRQContainer}>
        <TileGroup type='hero' gap='xs'>
          <Tile
            title='PQI'
            description='Prevention Quality Indicators in Inpatient Settings'
            to='/measures/pqi_resources'
            tag={['Prevention']}
          />
          <Tile
            title='IQI'
            description='Inpatient Quality Indicators'
            to='/measures/iqi_resources'
            tag={['Mortality', 'Utilization']}
          />
          <Tile
            title='PSI'
            description='Patient Safety Indicators'
            to='/measures/psi_resources'
            tag={['Complications', ' Adverse events']}
          />
        </TileGroup>

        <TileGroup type='hero' gap='xs'>
          <Tile
            title='PDI'
            description='Pediatric Quality Indicators'
            to='/measures/pdi_resources'
            tag={['Complications', 'Prevention', ' Adverse events']}
          />
          <Tile
            title='PQE'
            description='Prevention Quality Indicators in Emergency Department Settings'
            subDescription='(ED PQI is now PQE)'
            to='/measures/pqe_resources'
            tag={['Prevention']}
          />
          <Tile
            title='MHI'
            description='Maternal Health Indicators'
            subDescription=''
            to='/measures/mhi_resources'
            tag={['Morbidities', 'Mortality']}
            promote
          />
        </TileGroup>
      </Container>
    </div>
  );
};
