const MHI_ITEMS = [
  {
    name: 'TechSpecs',
    title: 'Maternal Health Indicators Technical Specifications',
    filename: 'MHI_2024_ICD10_techspecs_pdf.zip',
    path: '/Downloads/Modules/MHI/V2024/TechSpecs/',
    size: '951 KB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    dateModified: '2024-09-23',
  },
  {
    name: 'TechSpecsExcel',
    title: 'Maternal Health Indicators Technical Specifications (Excel Format)',
    filename: 'MHI_2024_ICD10_techspecs_excel.zip',
    path: '/Downloads/Modules/MHI/V2024/TechSpecs/',
    size: '419 KB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    dateModified: '2024-09-23',
  },
  {
    name: 'IndividualMeasureTechnicalSpecifications',
    title: 'Individual Measure Technical Specifications (v2024 coding)',
    desc: 'Breaks down calculations used to formulate MHIs, including a brief description of the measures, numerator and denominator information, and details on cases that should be excluded from calculations.',
    url: '/measures/MHI_TechSpec',
    aria: 'View MHI Technical Specifications',
    cta: 'Learn More',
  },
  {
    name: 'BenchmarkDataTables',
    title: 'Benchmark Data Tables for v2024 ICD-10-CM/PCS',
    desc: 'Tables of nationwide comparative rates for MHIs including observed rate, numerator, and denominator data for each indicator overall and stratified by sex and age group.',
    filename: 'Version_2024_Benchmark_Tables_MHI.pdf',
    path: '/Downloads/Modules/MHI/V2024/',
    size: '410 KB',
    format: 'PDF',
    dateModified: '2024-09-23',
  },
];

export default MHI_ITEMS;
